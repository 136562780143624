<template>
  <div id="question">
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <PageHeader clinic-name="築地" path-name="tsukiji"/>

        <div class="content">
          <div class="h1">よくあるご質問</div>

          <div class="detail">
            <el-row type="flex">

              <!--左侧的各个问题-->
              <el-col :span="17">
                <div class="question" v-for="(item, index) in question" :id="'q'+index">
                  <div class="title">Q. {{item.question}}</div>
                  <div class="text">
                    <!--<el-link class="btn" :underline="false">続きを表示</el-link>-->
                    {{item.answer}}
                  </div>
                </div>
              </el-col>

              <!--右侧的问题一览-->
              <el-col :span="7">
                <div class="list">
                  <div class="qa-title">
                    <span class="title-ja">ご質問一覧</span>
                    <span class="title-en">Q&A</span>
                  </div>
                  <div class="qa-separator"/>
                  <div v-for="(item, index) in question" class="qa-question">
                    <el-link :href="'#q'+index" :underline="false">{{item.question}}</el-link>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>

      </el-col>
    </el-row>

    <PageFooter/>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
export default {
  name: "Question",
  components: {PageFooter, PageHeader},
  data() {
    return {
      question: [],
    }
  },
  created() {
    axios.get(`${this.GLOBAL.host}/t/question/all`).then(resp => {
      this.question = resp.data
    })
  }
}
</script>

<style scoped>
#question {
  border-top: 10px solid #1796c4;
}

.h1 {
  font-family: "ヒラギノ丸ゴProN W4", "Hiragino Maru Gothic ProN", "HG丸ｺﾞｼｯｸM-PRO", HGMaruGothicMPRO, "メイリオ", Meiryo, Osaka, "MS Pゴシック", "MS PGothic", sans-serif;
  font-size: 24px;
  color: #333;

  margin: 50px 50px;
}

.question {
  background-color: #ffffff;
  margin-bottom: 40px;
  border-radius: 8px;
  box-shadow: 3px 3px 5px #999;

  padding: 40px;
}
.question div {
  font-family: "ヒラギノ丸ゴProN W4", "Hiragino Maru Gothic ProN", "HG丸ｺﾞｼｯｸM-PRO", HGMaruGothicMPRO, "メイリオ", Meiryo, Osaka, "MS Pゴシック", "MS PGothic", sans-serif;
  font-size: 16px;
  /*text-align: justify;*/
  line-height: 1.5;
}
.question .title {
  color: #e88383;
  margin-bottom: 10px;
}
.question .text {
  color: #333;
  white-space: pre-line;

  /*也是解决按钮显示问题*/
  /*display: flex;*/
}
/*解决加了按钮显示问题*/
/*.text::before {*/
/*  content: '';*/
/*  float: right;*/
/*  width: 0;*/
/*  !*height: calc(100%- 24px);*!*/
/*  height: 100%;*/
/*  margin-bottom: -15px;*/
/*}*/
.text {
  /*只显示四行，多于四行，省略显示，并且有按钮展开*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  /*display: -webkit-box;*/
  /*-webkit-line-clamp: 4; !*行数*!*/
  /*-webkit-box-orient: vertical;*/
}
.btn {
  color: #e88383;

  float: right;
  /*margin-top: 30px;*/
  clear: both;
}
.btn:hover {
  color: rgba(232, 131, 131, 0.7);
}


/*右侧list*/
.list {
  background-color: #ffffff;
  margin-left: 30px;
  border-radius: 7px;
  padding: 50px 20px;
}
.qa-title .title-ja, .qa-title .title-en {
  font-family: "ヒラギノ丸ゴProN W4", "Hiragino Maru Gothic ProN", "HG丸ｺﾞｼｯｸM-PRO", HGMaruGothicMPRO, "メイリオ", Meiryo, Osaka, "MS Pゴシック", "MS PGothic", sans-serif;
  font-weight: bold;
}
.qa-title .title-ja {
  font-size: 16px;
  color: #333;
}
.qa-title .title-en {
  font-size: 13px;
  color: #aaa;
  margin-left: 13px;
  letter-spacing: 4px;
}
.qa-separator {
  border-top: 1px dotted #333;
  margin: 10px 0;
}
.qa-question a {
  font-family: "ヒラギノ丸ゴProN W4", "Hiragino Maru Gothic ProN", "HG丸ｺﾞｼｯｸM-PRO", HGMaruGothicMPRO, "メイリオ", Meiryo, Osaka, "MS Pゴシック", "MS PGothic", sans-serif;
  font-size: 14px;
  color: rgba(232, 131, 131, 1);
  margin: 7px 0;
  line-height: 1.7;
}
.qa-question a:hover {
  color: rgba(232, 131, 131, 0.7);
}
</style>
